.cont-media-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 0px 10px;
    height: auto;
    flex-direction: column;
}

@media only screen and (min-width: 600px) {
    .cont-media-section {
        padding: 0px 20px 0px 20px;
    }
}